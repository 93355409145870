import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core'
import Layout from '../components/layout'
import HeaderOnly from '../components/news/headerOnly'
import PullQuote from '../components/PullQuote'
import RelatedArticles from '../components/RelatedArticles'
import MediaTabs from '../components/mediaTabs'
import sharedStyles from '../styles/global'
import MissingImage from '../components/missingImage'
import Caption from '../components/caption'
import FullWidth from '../components/news/fullWidth'
import SideBySide from '../components/news/sideBySide'
import FloatingImage from '../components/news/floatingImage'
import { filterArticles, findImage } from '../services/articleService'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  wrapper: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },
  imageSection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
  },
  headingSpacing: {
    marginBottom: '15px',
  },
  tagSpacing: {
    marginTop: '2vh',
    paddingBottom: '0.8rem',
  },
  caption: {
    paddingTop: '1vh',
  },
  text: {
    paddingTop: '5vh',
    width: '80%',
    margin: 'auto',
  },
  contentSpacing: {
    marginTop: '5vh',
    marginBottom: '5vh',
  },
  imageSpacing: {
    marginTop: '2vh',
    marginBottom: '2vh',
  },
  metadata: {
    paddingTop: '10px',
    opacity: 0.6,
  },
  menuSpacer: {
    marginBottom: '2vh',
    marginTop: '2vh',
  },
}))

function relateImages(node, allImages) {
  //-- relate header
  node.Header = findImage(node.Header, allImages, true)
  node.image1 = findImage(node.image1, allImages, true)
  node.image2 = findImage(node.image2, allImages, true)
  node.image3 = findImage(node.image3, allImages, true)
  node.image4 = findImage(node.image4, allImages, true)
  return node
}

const NewsTemplate = ({ data, pageContext }) => {
  const css = useStyles()
  const allImages = pageContext.images
  const allNews = filterArticles(data.allStrapiNew.nodes)
  const currentArticle = relateImages(
    allNews.find((article) => article.id === pageContext.id),
    allImages
  )
  if (currentArticle.pullquote && typeof currentArticle.pullquote.image === 'string') {
    const foundImg = allImages.find((img) => img.id === `Image_${currentArticle.pullquote.image}`)
    if (foundImg) currentArticle.pullquote.image = foundImg
  }
  const view = currentArticle.Layout

  const renderContent = (layout) => {
    switch (layout) {
      case 'Side_By_Side':
        return <SideBySide css={css} article={currentArticle}></SideBySide>
      case 'Full_Width':
        return <FullWidth css={css} article={currentArticle}></FullWidth>
      case 'Float_Left':
        return <FloatingImage css={css} article={currentArticle} direction={'left'}></FloatingImage>
      case 'Float_Right':
        return <FloatingImage css={css} article={currentArticle} direction={'right'}></FloatingImage>
      default:
        return <HeaderOnly css={css} article={currentArticle}></HeaderOnly>
    }
  }

  return (
    <Layout>
      <article className={[css.content, css.menuSpacer].join(' ')}>
        <MediaTabs selected={0} />

        {currentArticle.Header &&
        currentArticle.Layout === 'Header_Only' &&
        currentArticle.Header.File?.localFile.childImageSharp ? (
          <div className={css.imageSection}>
            <figure>
              <Img fluid={currentArticle.Header.File.localFile.childImageSharp.fluid} alt={currentArticle.Header.Alt} />
              <figcaption>
                <Caption text={currentArticle.Header.Caption} credit={currentArticle.Header.Credit} />
              </figcaption>
            </figure>
          </div>
        ) : (
          <></>
        )}

        <div>
          <div>
            <div className={[css.pt20, css.sansPro, css.bold, css.uppercase, css.opacity60, css.tagSpacing].join(' ')}>
              {currentArticle.tags.map((tag) => tag.Name).join(', ')}
              &nbsp;
            </div>
            <h1 className={[css.sansPro, css.pt70, css.bold, css.headingSpacing].join(' ')}>{currentArticle.Title}</h1>

            {currentArticle.Subtitle && (
              <div className={[css.sansPro, css.pt30, css.semiBold, css.headingSpacing].join(' ')}>
                {currentArticle.Subtitle}
              </div>
            )}

            <div className={[css.pt20, css.sansPro, css.regular, css.opacity60].join(' ')}>
              {currentArticle.author && <p>By {currentArticle.author.Name}</p>}

              <p>{currentArticle.Publish}</p>
            </div>
          </div>

          <div className={css.contentSpacing}>{renderContent(view)}</div>
          {/*TODO: This fails if pullQuote does not have an image */}
          {currentArticle.pullquote && (
            <PullQuote
              quote={currentArticle.pullquote.Quote}
              source={currentArticle.pullquote.Source}
              sourceSubtitle={currentArticle.pullquote.SourceSubtitle}
              backgroundImage={currentArticle.pullquote.image}
            />
          )}
          {currentArticle.tags.length > 0 && (
            <div id="RelatedArticles" className={css.contentSpacing}>
              <RelatedArticles
                tags={currentArticle.tags}
                articles={allNews.filter((news) => {
                  return news.id != pageContext.id
                })}
                images={allImages}
              />
            </div>
          )}
        </div>
      </article>
    </Layout>
  )
}

export default NewsTemplate

export const query = graphql`
  query NewsTemplate {
    allStrapiNew(sort: { fields: Publish, order: DESC }) {
      nodes {
        id
        Title
        Subtitle
        author {
          Name
        }
        Slug
        Publish(formatString: "MMMM D, YYYY")
        FormatedContent {
          data {
            FormatedContent
          }
        }
        Layout
        SubHeading1
        SubHeading2
        SubHeading3
        SubHeading4
        SubHeading5
        Text1 {
          data {
            Text1
          }
        }
        Text2 {
          data {
            Text2
          }
        }
        Text3 {
          data {
            Text3
          }
        }
        Text4 {
          data {
            Text4
          }
        }
        Text5 {
          data {
            Text5
          }
        }
        Header {
          id
          Alt
          Caption
          File {
            id
          }
        }
        image1 {
          id
          Alt
          Caption
          File {
            id
          }
        }
        image2 {
          id
          Alt
          Caption
          File {
            id
          }
        }
        image3 {
          id
          Alt
          Caption
          File {
            id
          }
        }
        image4 {
          id
          Alt
          Caption
          File {
            id
          }
        }
        images {
          id
          File {
            id
          }
          Caption
          Credit
          Title
          Alt
        }
        tags {
          Name
        }
        pullquote {
          image {
            id
          }
          Quote
          Source
        }
      }
    }
    missingImage: file(ext: { eq: ".jpg" }, name: { eq: "not-found" }) {
      id
      name
      publicURL
      childImageSharp {
        fluid(maxHeight: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
