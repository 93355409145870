import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core'
import sharedStyles from '../styles/global'
import ImageOverlay from './imageOverlay'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  headerLayout: {
    display: 'grid',
    gridTemplateColumns: '2rem 1fr',
    gridTemplateRows: '1fr',
  },
  headerSpacing: {
    margin: '0rem',
    marginBottom: '0.3rem',
  },
  headerMobile: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
}))

const ImageHeader = (props) => {
  const theme = useTheme()
  const css = useStyles(theme)
  const { title, color, image } = props
  const cssSettings = {
    crop: false,
    image: {
      height: '200px',
    },
    container: {
      maxHeight: '200px',
    },
    text: {
      paddingRight: '1rem',
      paddingLeft: '4rem',
    },
    overlay: {},
  }

  const headerCss = []
  if (color) {
    headerCss.push(css.headerLayout)
    cssSettings.overlay.background = ''
    cssSettings.text.paddingLeft = '2rem'
  }
  return (
    <div className={[css.headerSpacing, headerCss].join(' ')}>
      {color && <div style={{ backgroundColor: color }} />}
      <ImageOverlay alt={title} css={cssSettings} image={image}>
        <h1 className={[css.sansProBold, css.uppercase, css.headerMobile, css.pt65].join(' ')}>{title}</h1>
      </ImageOverlay>
    </div>
  )
}

export default ImageHeader
