exports.isIE = () => {
  let result = false
  if (typeof window !== 'undefined') {
    result = window.navigator.userAgent.indexOf('Trident') > -1
  }
  return result
}

exports.getQueryStringParams = (url) => {
  if (typeof window !== 'undefined') {
    const query = window.location.search
    return this.extractQueryParam(query)
  } else {
    if (!url) url = ''
    return this.extractQueryParam(url)
  }
}

exports.extractQueryParam = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        let [key, value] = param.split('=')
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
        return params
      }, {})
    : {}
}

exports.determineInitialTab = (url, tabNames) => {
  if (!url) url = ''
  const p = this.extractQueryParam(url)
  let tIndex = tabNames?.findIndex((tabName) => {
    return tabName.toLowerCase() === decodeURIComponent(p.tab)
  })
  if (tIndex < 0) tIndex = 0
  return tIndex
}

exports.determineActiveTab = (tab, tabNames, setTabParam, setActiveTab) => {
  let specifiedTabIdx = -1
  let tabParamValue = ''
  if (tab) {
    tabParamValue = decodeURIComponent(tab)
    specifiedTabIdx = tabNames.findIndex((tabName) => {
      return tabName.toLowerCase() === decodeURIComponent(tab)
    })
  }
  if (specifiedTabIdx < 0) {
    specifiedTabIdx = 0 // default
    tabParamValue = tabNames[specifiedTabIdx].toLowerCase()
    setTabParam(tabParamValue)
  }
  setActiveTab(specifiedTabIdx)
}
