import React, { useState, useEffect } from 'react'

import { makeStyles, useTheme, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../components/layout'
import SubMenu from '../components/participateSubMenus'
import sharedStyles from '../styles/global'
import SEO from '../components/seo'
import ContactUs from '../components/contactUs'
import VisitNASA from '../components/VisitNASA'
import ImageHeader from '../components/imageHeader'
import WithLocation from '../components/withLocation'

import { getQueryStringParams, determineInitialTab } from '../services/utilityService'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      aria-labelledby={`scrollable-auto-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  bluebox: {
    backgroundColor: '#dcecfc',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: '#cbe4fe',
    padding: '10px',
    margin: theme.spacing(3, 0, 3, 0),
  },
  aLink: { textDecoration: 'none' },
  panelRoot: {
    boxShadow: 'none',
    borderBottom: 'solid 1px #e4e4e4',
    '&.MuiAccordion-root:before': {
      content: 'none',
    },
  },
  accordionBlock: {
    // paddingBottom:'50px',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '20px',
  },
  journalDiv: {
    fontSize: '11px',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingRight: '20px',
    paddingTop: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      paddingTop: '20px',
      justifyContent: 'center',
    },
  },
  flexBox: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      maxWidth: '100%',
      justifyContent: 'left',
    },
  },
  flexBoxNoWrap: {
    display: 'flex',
    paddingTop: '10px',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      maxWidth: '350px',
      justifyContent: 'center',
    },
  },
  signalContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '20px',
    '@media (max-width:1100px)': {
      gridTemplateColumns: 'repeat(3, 1fr)', // 3 cards per row at 1100px or less
    },
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(2, 1fr)', // Mobile size: 2 card per row at 768px or less
    },
  },
  signalCard: {
    textAlign: 'center',
    '& a': {
      fontSize: '11px',
      fontWeight: 'bold',
    },
  },
  lineHangout: { lineHeight: 2 },
  roundedCorner: {
    borderRadius: '5px',
    maxWidth: '100%',
  },
  imgFlexIntern: {
    maxWidth: '200px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: '#cbe4fe',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
      justifyContent: 'center',
    },
  },
  bottomDiv: {
    paddingTop: '30px',
    paddingBottom: '10px',
  },
  embededVideo: {
    height: '400px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '250px',
    },
  },
}))

const ParticipatePage = (props) => {
  const { data, location } = props
  const theme = useTheme()
  const css = useStyles(theme)

  const tabNames = ['interns', 'educators', 'visit with nasa', 'contact us']

  const [activeTab, setActiveTab] = useState(() =>
    // eslint-disable-next-line react/destructuring-assignment
    determineInitialTab(props.location.href, tabNames)
  )

  function handleTabChange(evt, newTabIndex) {
    setActiveTab(newTabIndex)
  }
  const paragraphBaseStyles = [css.aboutBodyText, css.pt22, css.openSans, css.opacity90].join(' ')

  if (typeof window !== 'undefined') {
    window.addEventListener('popstate', (data) => {
      const { state } = data
      if (state && state.tabIndex >= 0) {
        setActiveTab(data.state.tabIndex)
      } else {
        setActiveTab(0)
      }
    })
  }

  const url = props.location.href || ''
  const queryParams = getQueryStringParams(url)
  const [tabParam, setTabParam] = useState('')

  useEffect(() => {
    determineActiveTab()
  }, [tabParam])

  function determineActiveTab() {
    let specifiedTabIdx = -1
    let tabParamValue = ''
    if (queryParams.tab) {
      tabParamValue = decodeURIComponent(queryParams.tab)
      specifiedTabIdx = tabNames.findIndex((tabName) => tabName.toLowerCase() === decodeURIComponent(queryParams.tab))
    }
    if (specifiedTabIdx < 0) {
      specifiedTabIdx = 0 // default
      tabParamValue = tabNames[specifiedTabIdx].toLowerCase()
      setTabParam(tabParamValue)
    }
    setActiveTab(specifiedTabIdx)
  }
  function getQueryVariable(variable) {
    if (typeof window !== 'undefined') {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return false
    }
  }
  const [expandedPanel, setExpandedPanel] = React.useState(false)
  const handleTechPanelChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }
  function expandPanel(panel) {
    const panelName = getQueryVariable('panel')
    let rtn = false
    panel == panelName ? (rtn = true) : (rtn = false)
    return rtn
  }
  const [hangoutExpanded, setHangoutExpanded] = useState(false)
  // const [hangoutExpanded, setHangoutExpanded] = useState(false)

  const handleHangoutExpanded = () => {
    setHangoutExpanded(!hangoutExpanded)
  }
  const [activitiesExpanded, setActivitiesExpanded] = useState(false)
  const handleActivitiesExpanded = () => {
    setActivitiesExpanded(!activitiesExpanded)
  }
  const [worksheetsExpanded, setWorksheetsExpanded] = useState(false)
  const handleWorksheetsExpanded = () => {
    setWorksheetsExpanded(!worksheetsExpanded)
  }

  const [digitalExpanded, setDigitalExpanded] = useState(false)
  const handleDigitalExpanded = () => {
    setDigitalExpanded(!digitalExpanded)
  }
  const [exploreExpanded, setExploreExpanded] = useState(false)
  const handleExploreExpanded = () => {
    setExploreExpanded(!exploreExpanded)
  }

  return (
    <Layout>
      <SEO title="Participate" />
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>

      <ImageHeader image={data.headerImage} title="PARTICIPATE" />
      <div className={css.content}>
        <SubMenu changed={handleTabChange} selected={activeTab} />
      </div>

      <TabPanel className={paragraphBaseStyles} index={0} value={activeTab}>
        <div className={css.content}>
          <div>
            <h2
              className={[css.sansProSemiBold, css.pt40, css.uppercase, css.regular, css.tabHeader, css.h2].join(' ')}
            >
              INTERNS
            </h2>
            <br />
            The Space Communications and Navigation (SCaN) Internship Project (SIP) at the NASA Goddard Space Flight
            Center is the shining star of the Exploration and Space Communications (ESC) project’s training and
            recruitment efforts. SIP prepares young professionals with the skills, experience, and connections they need
            to thrive in the NASA workplace and beyond. Each intern is guided to success by an expert mentor, networking
            and engagement resources, and a cross-center intern community. Every intern experience is different, but all
            SIP opportunities task interns to work with their mentors on a semester- or year-long project of measurable
            importance to NASA’s mission.
            <br />
            <br />
            <br />
            <iframe
              className={css.embededVideo}
              src="https://www.youtube.com/embed/GP6ZjNE-Vmk"
              title="Insert SIP Summer 2023 Video"
            />
          </div>
          <div className={css.bluebox}>
            <h3 className={[css.sansProSemiBold, css.regular].join(' ')}>Apply to be a NASA Intern</h3>
            <br />
            <h4 className={[css.pt26, css.regular].join(' ')}>
              Apply for the SCaN Internship Project (SIP) by searching for the keyword “SCaN” on the{' '}
              <a href="https://stemgateway.nasa.gov/public/s/explore-opportunities" target="_new">
                NASA STEM Gateway
              </a>
              . <br />
              <br />
              To learn about other NASA internship opportunities, check out NASA’s{' '}
              <a href="https://www.nasa.gov/stem/about.html" target="_new">
                STEM Engagement
              </a>{' '}
              page.
            </h4>
          </div>
          <div>
            <h2
              className={[css.sansProSemiBold, css.pt40, css.uppercase, css.regular, css.tabHeader, css.h2].join(' ')}
            >
              The Intern Impact
            </h2>
            <br />
            <p>
              SIP interns contribute to ESC in a variety of roles across many diverse disciplines from cybersecurity to
              public outreach. All intern projects further ESC’s mission; past interns have enhanced network
              capabilities, patented unique communications technologies, and improved the efficiency of agency
              operations.
            </p>
            <p>See examples of previous interns’ projects in our Intern Journals, linked below:</p>

            <div style={{ marginTop: '20px' }}>
              <div className={css.flexBox}>
                <div className={css.signalContainer}>
                  <div className={css.signalCard}>
                    <a className={css.aLink} href="/static-files/signal-summer-2024.pdf" target="_new">
                      <img
                        alt="Summer 2024 Journal"
                        className={css.roundedCorner}
                        src={data.PageParticipateTabIntern5.childImageSharp.fluid.src}
                      />
                      <br />
                      Summer 2024 Journal
                    </a>
                  </div>
                  <div className={css.signalCard}>
                    <a className={css.aLink} href="/static-files/SIGNAL Summer 2023.pdf" target="_new">
                      <img
                        alt="Summer 2023 Journal"
                        className={css.roundedCorner}
                        src={data.PageParticipateTabIntern4.childImageSharp.fluid.src}
                      />
                      <br />
                      Summer 2023 Journal
                    </a>
                  </div>
                  <div className={css.signalCard}>
                    <a className={css.aLink} href="/static-files/SIP Journal 2022.pdf" target="_new">
                      <img
                        alt="Summer 2022 Journal"
                        className={css.roundedCorner}
                        src={data.PageParticipateTabIntern1.childImageSharp.fluid.src}
                      />
                      <br />
                      Summer 2022 Journal
                    </a>
                  </div>
                  <div className={css.signalCard}>
                    {' '}
                    <a className={css.aLink} href="/static-files/SIP Journal 2021.pdf" target="_new">
                      <img
                        alt="Summer 2021 Journal"
                        className={css.roundedCorner}
                        src={data.PageParticipateTabIntern2.childImageSharp.fluid.src}
                      />
                      <br />
                      Summer 2021 Journal
                    </a>
                  </div>
                  <div className={css.signalCard}>
                    <a className={css.aLink} href="/static-files/SIP Journal 2020.pdf" target="_new">
                      <img
                        alt="Summer 2020 Journal"
                        className={`${css.roundedCorner}`}
                        src={data.PageParticipateTabIntern3.childImageSharp.fluid.src}
                      />
                      <br />
                      Summer 2020 Journal
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>

      <TabPanel className={paragraphBaseStyles} index={1} value={activeTab}>
        <div className={css.content}>
          <h2 className={[css.sansProSemiBold, css.pt40, css.uppercase, css.regular, css.tabHeader, css.h2].join(' ')}>
            EDUCATORS
          </h2>
          <br />
          <p>
            The Exploration and Space Communications (ESC) develops educational activities for a variety of age groups.
            These activities aim to assist educators in teaching students about the functionality and importance of
            space communications and navigation.
            <br />
            <br />
            <br />
          </p>
          <div key="divEducators">
            {/* // ########################################### ACTIVITIES ##################################################### */}
            {/* // ########################################### ACTIVITIES ##################################################### */}
            {/* // ########################################### ACTIVITIES ##################################################### */}
            <Accordion
              className={css.panelRoot}
              defaultExpanded={expandPanel('activities')}
              onChange={handleActivitiesExpanded}
            >
              <AccordionSummary
                aria-controls="activities"
                expandIcon={<ExpandMoreIcon />}
                id="activities"
                style={{
                  backgroundColor: activitiesExpanded ? 'rgba(0, 159, 242, 0.08)' : 'transparent',
                }}
              >
                <h3 className={[css.pt30, css.bold, css.capitalize].join(' ')}>Activities</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className={css.accordionBlock}>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Telepong</h3>
                    </p>
                    <p>
                      <h4>Grades 5+</h4>
                    </p>
                    <br />
                    <p>
                      <b>Next Generation Science Standards:</b> Engineering Design (MS-PS4-1, MS-ETS1)
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <img className={css.imgFlexIntern} src={data.participateImage15.childImageSharp.fluid.src} />
                      </Box>
                      <Box p={1}>
                        In this activity, students will create an “antenna” or “receiver” out of re-used materials.
                        After construction is complete, the students test their design by throwing “data” (in this case,
                        ping pong balls) across the room and comparing the message to test the success of their
                        receivers.
                        <br />
                        <br />
                        <a href="/static-files/telepong_part1_dig_en.pdf" target="_blank">
                          <b>Download Part 1</b>
                        </a>
                        <br />
                        <a href="/static-files/telepong_part2_dig_en.pdf" target="_blank">
                          <b>Download Part 2</b>
                        </a>
                        <br />
                        <a href="/static-files/telepong_part3_dig_en.pdf" target="_blank">
                          <b>Download Part 3</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Laser Maze</h3>
                    </p>
                    <p>
                      <h4>Grades 6-9</h4>
                    </p>
                    <br />
                    <p>
                      <b>Next Generation Science Standards:</b> Waves and their Applications in Technologies for
                      Information Transfer (MS-PS4), Engineering Design (MS-ETS1)
                    </p>

                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a href="/static-files/Laser Maze 1.0 EN 508.pdf" target="_blank">
                          <img className={css.imgFlexIntern} src={data.participateImage11.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        This activity explores the properties of light reflection and refraction through an engineering
                        design process challenge. NASA is developing a new method for transmitting more data: lasers!
                        Students will use mirrors to simulate transmitting data via laser communications by using the
                        properties of light reflection and refraction to navigate around obstacles.
                        <br />
                        <br />
                        <a href="/static-files/Laser Maze 1.0 EN 508.pdf" target="_blank">
                          <b>Download</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>LASERboard</h3>
                    </p>
                    <p>
                      <h4>Grades 5-8</h4>
                    </p>
                    <br />
                    <p>
                      <b>Next Generation Science Standards:</b> Waves and their Applications in Technologies for
                      Information Transfer (MS-PS4)
                    </p>

                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a href="/static-files/Laser Activity Board 1.0 EN 508.pdf" target="_blank">
                          <img className={css.imgFlexIntern} src={data.participateImage12.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        Students strengthen their understanding of the electromagnetic spectrum, specifically lasers and
                        their applications, through a series of math, writing, and graphing challenges. This series of
                        activities can be completed together or in parts.
                        <br />
                        <br />
                        <a href="/static-files/Laser Activity Board 1.0 EN 508.pdf" target="_blank">
                          <b>Download</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Feel the Noise</h3>
                    </p>
                    <p>
                      <h4>Grades 2-5</h4>
                    </p>
                    <br />
                    <p>
                      <b>Next Generation Science Standards:</b> Matter and its Interactions (2-PS1), Engineering Design
                      (K-2-ETS1, 3-5-ETS1), Forces and Interactions (3-PS2), Energy (4-PS3, 4-PS4), Waves and
                      Information (4-PS4)
                    </p>

                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a href="/static-files/Feel_The_Noise.pdf" target="_blank">
                          <img className={css.imgFlexIntern} src={data.participateImage1.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        In this activity, students use everyday metal objects and string to feel sound waves moving
                        through different substances, or mediums. The activity demonstrates vibrations interacting with
                        matter, building fundamentals for further learning about waves, energy, and communications.
                        <br />
                        <br />
                        <a href="/static-files/Feel_The_Noise.pdf" target="_blank">
                          <b>Download</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Missed Messages</h3>
                    </p>
                    <p>
                      <h4>Grades 5-8</h4>
                    </p>
                    <br />
                    <p>
                      <b>Next Generation Science Standards:</b> Engineering Design (3-5-ETS1, MS-ETS1)
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a href="/static-files/Missed_Messages.pdf" target="_blank">
                          <img className={css.imgFlexIntern} src={data.participateImage2.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        This activity challenges students to convey data from one side of a room to another. The task
                        starts simply but gets progressively more challenging as restrictions are added. The activity
                        shows the needs and constraints of space communications, while stretching the engineering
                        imagination of participants.
                        <br />
                        <br />
                        <a href="/static-files/Missed_Messages.pdf" target="_blank">
                          <b>Download</b>
                        </a>
                        <br />
                        <a href="https://www.youtube.com/watch?v=v-3rown_Euk" rel="noreferrer" target="_blank">
                          <b>Video Introduction</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Create Your Own Comic</h3>
                    </p>
                    <p>
                      <h4>Grades 2+</h4>
                    </p>
                    <br />
                    <p>
                      <b>Common Core State Standards:</b> Presentation of Knowledge and Ideas
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a
                          href="https://www.nasa.gov/sites/default/files/atoms/files/artemis_comic_activity_en.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img className={css.imgFlexIntern} src={data.participateImage3.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        Students write and illustrate the adventures of NASA’s Artemis astronauts in their own comic.
                        When they complete their work, they can submit scans at GSFC-SCaN-engagement@mail.nasa.gov. They
                        might have their comic featured on NASA’s social media! <br />
                        <br />
                        <b>Download</b>&nbsp;
                        <a
                          href="https://www.nasa.gov/sites/default/files/atoms/files/artemis_comic_activity_en.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          EN
                        </a>
                        &nbsp;|&nbsp;
                        <a
                          href="https://www.nasa.gov/sites/default/files/atoms/files/artemis_comic_activity_es.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          ES
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>It's Magnetic</h3>
                    </p>
                    <p>
                      <h4>Grades 1-8</h4>
                    </p>
                    <br />
                    <p>
                      <b>Next Generation Science Standards:</b> Motion and Stability: Forces and Interactions (3-PS2,
                      MS-PS2), Energy (MS-PS3)
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a
                          href="https://www.nasa.gov/sites/default/files/atoms/files/parent_activity_manual_-_its_magnetic_v1.0.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img className={css.imgFlexIntern} src={data.participateImage44.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        This scalable activity presents the properties of magnetism through a space communications lens.
                        Students learn about space navigation through orienteering and learn how communications can be
                        impacted by the interaction between Earth’s magnetic field and space weather. <br />
                        <br />
                        <a
                          href="https://www.nasa.gov/sites/default/files/atoms/files/parent_activity_manual_-_its_magnetic_v1.0.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Download</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* // ########################################### WORKSHEETS ##################################################### */}
            {/* // ########################################### WORKSHEETS ##################################################### */}
            {/* // ########################################### WORKSHEETS ##################################################### */}
            <Accordion
              className={css.panelRoot}
              defaultExpanded={expandPanel('worksheets')}
              onChange={handleWorksheetsExpanded}
            >
              <AccordionSummary
                aria-controls="worksheets"
                expandIcon={<ExpandMoreIcon />}
                id="worksheets"
                style={{
                  backgroundColor: worksheetsExpanded ? 'rgba(0, 159, 242, 0.08)' : 'transparent',
                }}
              >
                <h3 className={[css.pt30, css.bold, css.capitalize].join(' ')}>Worksheets</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className={css.accordionBlock}>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Money Mass-ematics</h3>
                    </p>
                    <p>
                      <h4>Grades 7-8</h4>
                    </p>
                    <br />
                    <p>
                      <b>Common Core State Standards:</b> Ratios & Proportional Relations and Data
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a href="/static-files/Money Mass-ematics 1.0 EN 508.pdf" target="_blank">
                          <img className={css.imgFlexIntern} src={data.participateImage14.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        Students review their knowledge of mathematics and unit conversion by occupying the role of a
                        NASA resource analyst. <br />
                        <br />
                        <a href="/static-files/Money Mass-ematics 1.0 EN 508.pdf" target="_blank">
                          <b>Download</b>
                        </a>
                        <br />
                        <a href="/static-files/Money Mass-ematics Answer Key 1.0 EN 508.pdf" target="_blank">
                          <b>Answer Key</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Laser Links</h3>
                    </p>
                    <p>
                      <h4>Grades 1-4</h4>
                    </p>
                    <br />
                    <p>
                      <b>Common Core State Standards:</b> Measurement and Data
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a href="/static-files/Laser Links Activity.pdf" target="_blank">
                          <img className={css.imgFlexIntern} src={data.participateImage4.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        Using a ruler, students draw lines between as many satellites as needed to connect the
                        spacecraft to Earth. Careful about obstacles! <br />
                        <br />
                        <a href="/static-files/Laser Links Activity.pdf" target="_blank">
                          <b>Download</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Orienteering</h3>
                    </p>
                    <p>
                      <h4>Grades 3-5</h4>
                    </p>
                    <br />
                    <p>
                      <b>Common Core State Standards:</b> Measurement and Data
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a href="/static-files/Nav Orienteering Activity.pdf" target="_blank">
                          <img className={css.imgFlexIntern} src={data.participateImage10.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        Students will navigate through celestial objects to reach their destination - follow the
                        directions and map a journey through space! <br />
                        <br />
                        <a href="/static-files/Nav Orienteering Activity.pdf" target="_blank">
                          <b>Download</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Packing a Rocket</h3>
                    </p>
                    <p>
                      <h4>Grades 1-4</h4>
                    </p>
                    <br />
                    <p>
                      <b>Common Core State Standards:</b> Measurement and Data, Geometry
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/packing-a-rocket-activity.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img className={css.imgFlexIntern} src={data.participateImage5.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        Students pack for space! Cut out pieces on the space communications and navigation checklist and
                        arrange them to fit within the top of a NASA rocket.
                        <br />
                        <br />
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/packing-a-rocket-activity.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Download</b>
                        </a>
                        <br />
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/packing-a-rocket-answers.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Answer Key</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Measurement and Conversions</h3>
                    </p>
                    <p>
                      <h4>Grades 3-6</h4>
                    </p>
                    <br />
                    <p>
                      <b>Common Core State Standards:</b> Measurement and Data
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/measurement-and-conversions-activity.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img className={css.imgFlexIntern} src={data.participateImage6.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        Students learn more about measurement and unit conversions by helping NASA determine what size
                        rockets we will need for various communications satellites. <br />
                        <br />
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/measurement-and-conversions-activity.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Download</b>
                        </a>
                        <br />
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/measurement-and-conversions-answers.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Answer Key</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Geometry of Circles</h3>
                    </p>
                    <p>
                      <h4>Grades 9+</h4>
                    </p>
                    <br />
                    <p>
                      <b>Common Core State Standards:</b> Geometry, Measurement and Data, Volume
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/geometry-of-circles-activity.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img className={css.imgFlexIntern} src={data.participateImage7.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        NASA payload designers — the engineers who build spacecraft and satellites — need to make sure
                        that spacecraft can fit into the protective shell, or fairing, at the tip of our rockets.
                        Students use geometry to determine how to place NASA payloads into the smallest usable fairing.{' '}
                        <br />
                        <br />
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/geometry-of-circles-activity.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Download</b>
                        </a>
                        <br />
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/geometry-of-circles-answers.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Answer Key</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Irradiance</h3>
                    </p>
                    <p>
                      <h4>Grades 10+</h4>
                    </p>
                    <br />
                    <p>
                      <b>Common Core State Standards:</b> Algebra, Geometry, Measurement and Data
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/irradiance-activity.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img className={css.imgFlexIntern} src={data.participateImage8.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        Students learn how to measure irradiance, the amount of radiant energy flowing through a
                        two-dimension area and compare irradiance between two objects. <br />
                        <br />
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/irradiance-activity.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Download</b>
                        </a>
                        <br />
                        <a
                          href="https://www.nasa.gov/wp-content/uploads/2023/07/irradiance-instructors-answers.pdf"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Answer Key</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                  <Typography className={paragraphBaseStyles}>
                    <p>
                      <h3>Navigation Workbook</h3>
                    </p>
                    <p>
                      <h4>Grades 8+</h4>
                    </p>
                    <br />
                    <p>
                      <b>Common Core State Standards:</b> Algebra, Measurement and Data, Vector and Matrix Quantities
                    </p>
                    <Box className={css.flexBoxNoWrap}>
                      <Box p={1}>
                        <a
                          href="https://www.nasa.gov/directorates/somd/space-communications-navigation-program/stem-navigation-activities/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img className={css.imgFlexIntern} src={data.participateImage9.childImageSharp.fluid.src} />
                        </a>
                      </Box>
                      <Box p={1}>
                        In this workbook, students use basic mathematical concepts to explore simplified, real-world
                        example from NASA’s navigation engineers. <br />
                        <br />
                        <a
                          href="https://www.nasa.gov/directorates/somd/space-communications-navigation-program/stem-navigation-activities/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <b>Download</b>
                        </a>
                      </Box>
                    </Box>
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* // ########################################### HANGOUT ##################################################### */}
            {/* // ########################################### HANGOUT ##################################################### */}
            {/* // ########################################### HANGOUT ##################################################### */}
            <Accordion
              className={css.panelRoot}
              defaultExpanded={expandPanel('hangout')}
              onChange={handleHangoutExpanded}
            >
              <AccordionSummary
                aria-controls="hangout"
                expandIcon={<ExpandMoreIcon />}
                id="hangout"
                style={{
                  backgroundColor: hangoutExpanded ? 'rgba(0, 159, 242, 0.08)' : 'transparent',
                }}
              >
                <h3 className={[css.pt30, css.bold, css.capitalize].join(' ')}>Handouts & Coloring Sheets</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className={[css.lineHangout, css.bold].join(' ')}>
                  ILLUMA-T Fact Sheet&nbsp;
                  <a href="/static-files/ILLUMA-T Fact Sheet.pdf" target="_blank">
                    EN
                  </a>
                  <br />
                  ILLUMA-T Poster&nbsp;
                  <a href="/static-files/ILLUMA-T Poster.jpg" target="_blank">
                    EN
                  </a>
                  <br />
                  ILLUMA-T Bookmark&nbsp;
                  <a href="/static-files/ILLUMA-T Bookmark.pdf" target="_blank">
                    EN
                  </a>
                  <br />
                  ILLUMA-T Coloring Sheets&nbsp;
                  <a href="/static-files/ILLUMA-T Coloring Sheets.pdf" target="_blank">
                    EN
                  </a>
                  <br />
                  LCRD Bookmark:&nbsp;
                  <a
                    href="https://www.nasa.gov/wp-content/uploads/2023/07/lcrd-bookmark.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    EN
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    href="https://www.nasa.gov/wp-content/uploads/2023/07/lcrd-bookmark-espanol.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ES
                  </a>
                  <br />
                  LCRD Poster:&nbsp;
                  <a
                    href="https://www.nasa.gov/wp-content/uploads/2023/07/lcrd-poster.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    EN
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    href="https://www.nasa.gov/wp-content/uploads/2023/07/lcrd-poster-espanol.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ES
                  </a>
                  <br />
                  LCRD Fact Sheet:&nbsp;
                  <a
                    href="https://www.nasa.gov/sites/default/files/atoms/files/lcrd_fact_sheet.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    EN
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    href="https://www.nasa.gov/wp-content/uploads/2023/07/lcrd-fact-sheet-espanol.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ES
                  </a>
                  <br />
                  Laser Communications Fun Facts:&nbsp;
                  <a href="/static-files/Laser Communications Fun Facts.pdf" target="_blank">
                    EN
                  </a>
                  <br />
                  {/* TODO GET URL */}
                  LCRD Coloring Sheets:&nbsp;
                  <a
                    href="https://www.nasa.gov/wp-content/uploads/2023/07/lcrd-coloring-sheets.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    EN
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    href="https://www.nasa.gov/wp-content/uploads/2023/07/lcrd-coloring-sheets-espanol.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ES
                  </a>
                  <br />
                  NASA’s Search and Rescue Coloring Book:&nbsp;
                  <a
                    href="https://www.nasa.gov/wp-content/uploads/2023/09/nasa-search-and-rescue-coloring-book-0.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    EN
                  </a>
                  <br />
                  TBIRD Fact Sheet&nbsp;
                  <a href="/static-files/TBIRD Fact Sheet.pdf" target="_blank">
                    EN
                  </a>
                  <br />
                  TBIRD Poster&nbsp;
                  <a href="/static-files/TBIRD Poster.jpg" target="_blank">
                    EN
                  </a>
                  <br />
                  TBIRD Postcard&nbsp;
                  <a href="/static-files/TBIRD Postcard.pdf" target="_blank">
                    EN
                  </a>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* // ########################################### DIGITAL ##################################################### */}
            {/* // ########################################### DIGITAL ##################################################### */}
            {/* // ########################################### DIGITAL ##################################################### */}
            <Accordion
              className={css.panelRoot}
              defaultExpanded={expandPanel('digital')}
              onChange={handleDigitalExpanded}
            >
              <AccordionSummary
                aria-controls="digital"
                expandIcon={<ExpandMoreIcon />}
                id="digital"
                style={{
                  backgroundColor: digitalExpanded ? 'rgba(0, 159, 242, 0.08)' : 'transparent',
                }}
              >
                <h3 className={[css.pt30, css.bold, css.capitalize].join(' ')}>Digital Fun</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className={[css.lineHangout, css.bold].join(' ')}>
                  Kahoots:&nbsp;
                  <a
                    href="https://create.kahoot.it/course/6b17498e-47ad-43d1-b080-ab4f6c0d25e3"
                    rel="noreferrer"
                    target="_blank"
                  >
                    EN
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    href="https://create.kahoot.it/course/601fabe7-514a-48aa-9389-7cef4edd4704"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ES
                  </a>
                  <br />
                  LCRD Overview Presentation:&nbsp;
                  <a
                    href="https://www.nasa.gov/wp-content/uploads/2023/07/lcrd-overviewpresentation.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    EN
                  </a>
                  <br />
                  <br />
                  <br />
                  Artemis Videos:
                  <br />
                  <div className={css.flexBox}>
                    <div className={css.journalDiv}>
                      <a className={css.aLink} href="https://www.youtube.com/embed/xeMuqNZ1xHY" target="_new">
                        <img
                          alt="Artemis I: Empowered by NASA's Networks?"
                          className={css.roundedCorner}
                          src={data.artemisVideo1.childImageSharp.fluid.src}
                        />
                        <br />
                        Artemis I: Empowered by NASA's Networks?
                      </a>
                    </div>
                    <div className={css.journalDiv}>
                      <a className={css.aLink} href="https://www.youtube.com/embed/vBl3MZC8Iiw" target="_new">
                        <img
                          alt="Artemis I Communications Profile"
                          className={css.roundedCorner}
                          src={data.artemisVideo2.childImageSharp.fluid.src}
                        />
                        <br />
                        Artemis I Communications Profile
                      </a>
                    </div>
                    <div className={css.journalDiv}>
                      <a className={css.aLink} href="https://www.youtube.com/embed/MBGnrmXT06o" target="_new">
                        <img
                          alt="Lunar IceCube - An Artemis Secondary Payload"
                          className={css.roundedCorner}
                          src={data.artemisVideo3.childImageSharp.fluid.src}
                        />
                        <br />
                        Lunar IceCube - An Artemis Secondary Payload
                      </a>
                    </div>
                  </div>
                  <br />
                  LCRD Videos:
                  <br />
                  <div className={css.flexBox}>
                    <div className={css.journalDiv}>
                      <a className={css.aLink} href="https://www.youtube.com/embed/OAFkd5DdLZU" target="_new">
                        <img
                          alt="LCRD Mission Overview"
                          className={css.roundedCorner}
                          src={data.lcrdVideo1.childImageSharp.fluid.src}
                        />
                        <br />
                        LCRD Mission Overview
                      </a>
                    </div>
                    <div className={css.journalDiv}>
                      <a className={css.aLink} href="https://www.youtube.com/embed/KcBQclXOj7Y" target="_new">
                        <img
                          alt="LCRD’s Optical Ground Stations "
                          className={css.roundedCorner}
                          src={data.lcrdVideo2.childImageSharp.fluid.src}
                        />
                        <br />
                        LCRD’s Optical Ground Stations
                      </a>
                    </div>
                    <div className={css.journalDiv}>
                      <a className={css.aLink} href="https://svs.gsfc.nasa.gov/12440" target="_new">
                        <img
                          alt="LCRD Ready for Launch"
                          className={css.roundedCorner}
                          src={data.lcrdVideo3.childImageSharp.fluid.src}
                        />
                        <br />
                        LCRD Ready for Launch
                      </a>
                    </div>
                    <div className={css.journalDiv}>
                      <a className={css.aLink} href="https://svs.gsfc.nasa.gov/12440" target="_new">
                        <img
                          alt="LCRD Tours"
                          className={css.roundedCorner}
                          src={data.lcrdVideo4.childImageSharp.fluid.src}
                        />
                        <br />
                        LCRD Tours
                      </a>
                    </div>
                    <div className={css.journalDiv}>
                      <a className={css.aLink} href="https://www.youtube.com/embed/ymozJJuhG4o" target="_new">
                        <img
                          alt="Faces of Technology: Meet Glenn Jackson"
                          className={css.roundedCorner}
                          src={data.lcrdVideo5.childImageSharp.fluid.src}
                        />
                        <br />
                        Faces of Technology: Meet Glenn Jackson
                      </a>
                    </div>
                  </div>
                  <br />
                  Background Images:
                  <br />
                  <div className={css.flexBox}>
                    <div className={css.journalDiv}>
                      <a
                        className={css.aLink}
                        href={data.TBIRDvirtualBackground.childImageSharp.fluid.src}
                        target="_new"
                      >
                        <img
                          alt="TBIRD Virtual Background"
                          className={css.roundedCorner}
                          src={data.TBIRDvirtualBackground.childImageSharp.fluid.src}
                        />
                        <br />
                        TBIRD Virtual Background
                      </a>
                    </div>
                    <div className={css.journalDiv}>
                      <a
                        className={css.aLink}
                        href={data.ILLUMAvirtualBackgroundBackground.childImageSharp.fluid.src}
                        target="_new"
                      >
                        <img
                          alt="ILLUMA-T Virtual Background"
                          className={css.roundedCorner}
                          src={data.ILLUMAvirtualBackgroundBackground.childImageSharp.fluid.src}
                        />
                        <br />
                        ILLUMA-T Virtual Background
                      </a>
                    </div>
                    <div className={css.journalDiv}>
                      <a
                        className={css.aLink}
                        href={data.LCRDvirtualBackgroundBackground.childImageSharp.fluid.src}
                        target="_new"
                      >
                        <img
                          alt="LCRD Virtual Background"
                          className={css.roundedCorner}
                          src={data.LCRDvirtualBackgroundBackground.childImageSharp.fluid.src}
                        />
                        <br />
                        LCRD Virtual Background
                      </a>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* // ########################################### EXPLORE ##################################################### */}
            {/* // ########################################### EXPLORE ##################################################### */}
            {/* // ########################################### EXPLORE ##################################################### */}
            <Accordion
              className={css.panelRoot}
              defaultExpanded={expandPanel('explore')}
              onChange={handleExploreExpanded}
            >
              <AccordionSummary
                aria-controls="explore"
                expandIcon={<ExpandMoreIcon />}
                id="explore"
                style={{
                  backgroundColor: exploreExpanded ? 'rgba(0, 159, 242, 0.08)' : 'transparent',
                }}
              >
                <h3 className={[css.pt30, css.bold, css.capitalize].join(' ')}>Explore More</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className={[css.lineHangout, css.bold].join(' ')}>
                  <ul>
                    <li>
                      <a href="https://www.jpl.nasa.gov/missions/dsn" target="_new">
                        The Deep Space Network at NASA Jet Propulsion Laboratory
                      </a>
                    </li>
                    <li>
                      <a href="https://www1.grc.nasa.gov/space/scan/" target="_new">
                        Space Communications and Navigation at NASA Glenn Research Center
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nesdis.noaa.gov/about/k-12-education/jpss-education" target="_new">
                        Joint Polar Satellite System (JPSS) Education at NOAA{' '}
                      </a>
                    </li>
                    <li>
                      <a href="https://science.nasa.gov/learners/wavelength" target="_new">
                        NASA Wavelength
                      </a>
                    </li>
                    <li>
                      <a href="https://stemgateway.nasa.gov/connects/s/" target="_new">
                        NASA Connects
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.nasa.gov/directorates/heo/scan/communications/outreach/students/txt_kidszone.html"
                        target="_new"
                      >
                        SCaN Student Zone
                      </a>
                    </li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={css.bottomDiv}>
            <h3 className={[css.pt30, css.bold, css.capitalize].join(' ')}>Share Your Feedback</h3>
          </div>
          Have you tried one of these activities yourself or with a group? We’d love to see any pictures or hear about
          your experience! Contact us at{' '}
          <a href="mailto:gsfc-scan-engagement@mail.nasa.gov">gsfc-scan-engagement@mail.nasa.gov</a>.
        </div>
      </TabPanel>

      <TabPanel index={2} value={activeTab}>
        <div className={css.content}>
          <p style={{ marginLeft: 20 }}>
            <h2
              className={[css.sansProSemiBold, css.pt40, css.uppercase, css.regular, css.tabHeader, css.h2].join(' ')}
            >
              VISIT WITH NASA
            </h2>
          </p>
          <br />
          <p style={{ marginLeft: 20 }}>
            Interested in having a NASA SCaN expert speak to your class or group? The SCaN program is accepting requests
            for virtual visits during the coming calendar year. Request a virtual visit below.
            <br />
            <br />
          </p>
          {activeTab === 2 && <VisitNASA />}
        </div>
      </TabPanel>

      <TabPanel index={3} value={activeTab}>
        <div className={css.content}>{activeTab === 3 && <ContactUs />}</div>
      </TabPanel>
    </Layout>
  )
}

export default WithLocation(ParticipatePage)

export const pageQuery = graphql`
  query ParticipateQuery {
    strapiProject(Acronym: { eq: "SIP" }) {
      facts {
        Alt
        Content
        Image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 600) {
                src
              }
            }
          }
        }
      }
    }
    PageParticipateTabIntern1: file(relativePath: { eq: "participate/journal_2022.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PageParticipateTabIntern2: file(relativePath: { eq: "participate/journal_2021.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PageParticipateTabIntern3: file(relativePath: { eq: "participate/journal_2020.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PageParticipateTabIntern4: file(relativePath: { eq: "participate/signal2023_thumbnail.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PageParticipateTabIntern5: file(relativePath: { eq: "participate/signal2024-thumbnail.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage1: file(relativePath: { eq: "participate/feel_the_noise.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage2: file(relativePath: { eq: "participate/missed_messages.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage3: file(relativePath: { eq: "participate/create_comic.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage44: file(relativePath: { eq: "participate/its_magnetic.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage4: file(relativePath: { eq: "participate/laser_links.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage5: file(relativePath: { eq: "participate/packing_rocket.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage6: file(relativePath: { eq: "participate/measurement_conv.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage7: file(relativePath: { eq: "participate/geometry_circles.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage8: file(relativePath: { eq: "participate/irradiance.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage9: file(relativePath: { eq: "participate/nav_workbook.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage10: file(relativePath: { eq: "participate/orienteering.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage11: file(relativePath: { eq: "participate/th_laser_maze.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage12: file(relativePath: { eq: "participate/th_laser_board.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage13: file(relativePath: { eq: "participate/th_money_mass.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage14: file(relativePath: { eq: "participate/th_money_mass.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participateImage15: file(relativePath: { eq: "participate/th_telepong.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    digitalFun1: file(relativePath: { eq: "participate/lcrd_virtual_background.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    digitalFun2: file(relativePath: { eq: "participate/lcrd_wallpaper_1280x720.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    digitalFun3: file(relativePath: { eq: "participate/lcrd_wallpaper_1366x768.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    digitalFun4: file(relativePath: { eq: "participate/lcrd_wallpaper_1440x900.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    digitalFun5: file(relativePath: { eq: "participate/lcrd_wallpaper_1536x864.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 864) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    digitalFun6: file(relativePath: { eq: "participate/lcrd_wallpaper_1920x1080.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lcrdVideo1: file(relativePath: { eq: "participate/vid_lcrd_overview.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lcrdVideo2: file(relativePath: { eq: "participate/vid_lcrd_optical.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lcrdVideo3: file(relativePath: { eq: "participate/vid_lcrd_lighting.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lcrdVideo4: file(relativePath: { eq: "participate/vid_lcrd_labtour.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lcrdVideo5: file(relativePath: { eq: "participate/vid_faces_glenn.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    artemisVideo1: file(relativePath: { eq: "participate/vid_artemis_empowered.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    artemisVideo2: file(relativePath: { eq: "participate/vid_artemis_comms.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    artemisVideo3: file(relativePath: { eq: "participate/vid_lunar_icecube.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TBIRDvirtualBackground: file(relativePath: { eq: "participate/TBIRD Virtual Background.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ILLUMAvirtualBackgroundBackground: file(relativePath: { eq: "participate/ILLUMA-T Virtual Background.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LCRDvirtualBackgroundBackground: file(relativePath: { eq: "participate/lcrd_wallpaper_1920x1080.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "headers/participate.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
