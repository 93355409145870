import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import sharedStyles from '../styles/global'
import SEO from '../components/seo'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  MainHeader: {
    margin: '3rem 0rem 0.3rem 0rem',
  },
  mainUL: {
    listStyleType: 'none',
    marginLeft: '20px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '20px',
    },
  },
  subUL: {
    listStyleType: 'disc',
    marginLeft: '20px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '40px',
    },
  },
  subsubUL: {
    listStyleType: 'circle',
    marginLeft: '10px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '20px',
    },
  },
}))

const SiteMap = ({ data }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const slugs = data.allStrapiNew.edges.map((x) => x.node)
  return (
    <Layout>
      <SEO title="SiteMap" />
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <div>
        <div className={classes.content}>
          <h1 className={[classes.MainHeader, classes.uppercase].join(' ')}>SITEMAP</h1>
        </div>

        <div className={classes.content}>
          <div className="static-landing-page">
            <ul className={classes.mainUL}>
              <li>
                {' '}
                <Link to="/">Homepage</Link>
              </li>
              <li>
                {' '}
                <Link to="/about">About</Link>
              </li>
              <ul className={classes.subUL}>
                <li>
                  <Link to="/about?tab=overview">ESC Overview</Link>
                </li>
                <li>
                  <Link to="/about?tab=our%20networks">Our Networks</Link>
                </li>
                <li>
                  <Link to="/about?tab=technology">Technology</Link>
                  <ul className={classes.subsubUL}>
                    <li>
                      <Link to="/about?tab=technology&panel=tech-panel-0">Radio Frequency Communications</Link>
                    </li>
                    <li>
                      <Link to="/about?tab=technology&panel=tech-panel-1">Optical Communications</Link>
                    </li>
                    <li>
                      <Link to="/about?tab=technology&panel=tech-panel-2">New Communications Technologies</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/about?tab=impact">Impact</Link>
                </li>
                <li>
                  <Link to="/about?tab=history">History</Link>
                </li>
              </ul>
              <li>
                {' '}
                <Link to="/people">People</Link>
              </li>
              <ul className={classes.subUL}>
                <li>
                  <Link to="/people?tab=leadership">Leadership</Link>
                </li>
                <li>
                  <Link to="/people?tab=org%20chart">Org Chart</Link>
                </li>
                <li>
                  <Link to="/people?tab=profiles">Profiles</Link>
                </li>
              </ul>
              <li>
                {' '}
                <Link to="/projects">Projects</Link>
                <ul className={classes.subUL}>
                  <li>
                    <Link to="/projects/ACCESS">
                      Advanced Communications Capabilities for Exploration and Science Systems (ACCESS)
                    </Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/ACCESS?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/ACCESS?tab=tdrs%20fleet">TDRS Fleet</Link>
                      </li>
                      <li>
                        <Link to="/projects/ACCESS?tab=operational%20technologies">Operational Technologies</Link>
                      </li>
                      <li>
                        <Link to="/projects/ACCESS?tab=ongoing%20innovations">Ongoing Innovations</Link>
                      </li>
                      <li>
                        <Link to="/projects/ACCESS?tab=publications">Publications</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/CIS">Commercialization, Innovation, and Synergies (CIS) Office</Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/CIS?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/CIS?tab=industry%20engagement">Industry Engagement</Link>
                      </li>
                      <li>
                        <Link to="/projects/CIS?tab=mission%20engagement">Mission Engagement</Link>
                      </li>
                      <li>
                        <Link to="/projects/CIS?tab=civil%20%26%20defense%20space%20partnerships">
                          Civil & Defense Space Partnerships
                        </Link>
                      </li>
                      <li>
                        <Link to="/projects/CIS?tab=exploration%20portfolio">Exploration Portfolio</Link>
                      </li>
                      <li>
                        <Link to="/projects/CIS?tab=upcoming%20events">Upcoming Events</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/ILLUMA-T">
                      Integrated LCRD Low-Earth Orbit User Modem and Amplifier Terminal (ILLUMA-T)
                    </Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/ILLUMA-T?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/ILLUMA-T?tab=how%20it%20works">How It Works</Link>
                      </li>
                      <li>
                        <Link to="/projects/ILLUMA-T?tab=specialized%20technology">Specialized Technology</Link>
                      </li>
                      <li>
                        <Link to="/projects/ILLUMA-T?tab=illuma-t%20components">ILLUMA-T Components</Link>
                      </li>
                      <li>
                        <Link to="/projects/ILLUMA-T?tab=publications">Publications</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/INSPIRE">
                      Integrated Strategic Products, Information, and Resources Enterprise (INSPIRE)
                    </Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/INSPIRE?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/INSPIRE?tab=publications ">Publications</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/LCRD">Laser Communications Relay Demonstration (LCRD)</Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/LCRD?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/LCRD?tab=the%20ground%20segment">The Ground Segment</Link>
                      </li>
                      <li>
                        <Link to="/projects/LCRD?tab=opportunities%20for%20experimenters">
                          Opportunities for Experimenters
                        </Link>
                      </li>
                      <li>
                        <Link to="/projects/LCRD?tab=optical%20infusion">Optical Infusion</Link>
                      </li>
                      <li>
                        <Link to="/projects/LCRD?tab=technology">Technology</Link>
                      </li>
                      <li>
                        <Link to="/projects/LCRD?tab=publications">Publications</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/LEMNOS">
                      Laser Enhanced Mission Communication Navigation and Operations Services (LEMNOS) Pipeline
                    </Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/LEMNOS?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/LEMNOS?tab=benefits">Benefits</Link>
                      </li>
                      <li>
                        <Link to="/projects/LEMNOS?tab=history">History</Link>
                      </li>
                      <li>
                        <Link to="/projects/LEMNOS?tab=publications">Publications</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/LCRNS">Lunar Communications Relay and Navigation Systems (LCRNS)</Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/LCRNS?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/LCRNS?tab=development">Development</Link>
                      </li>
                      <li>
                        <Link to="/projects/LCRNS?tab=technology">Technology</Link>
                      </li>
                      <li>
                        <Link to="/projects/LCRNS?tab=publications">Publications</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/PNT">Navigation</Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/PNT?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/PNT?tab=gnss%20policy">GNSS Policy</Link>
                      </li>
                      <li>
                        <Link to="/projects/PNT?tab=lugre">LuGRE</Link>
                      </li>
                      <li>
                        <Link to="/projects/PNT?tab=flight%20dynamics%20facility">Flight Dynamics Facility</Link>
                      </li>
                      <li>
                        <Link to="/projects/PNT?tab=technology">Technology</Link>
                      </li>
                      <li>
                        <Link to="/projects/PNT?tab=publications">Publications</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/NSN">Near Space Network (NSN)</Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/NSN?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/NSN?tab=services">Services</Link>
                      </li>
                      <li>
                        <Link to="/projects/NSN?tab=customers">Customers</Link>
                      </li>
                      <li>
                        <Link to="/projects/NSN?tab=providers">Providers</Link>
                      </li>
                      <li>
                        <Link to="/projects/NSN?tab=spectrum%20management">Spectrum Management</Link>
                      </li>
                      <li>
                        <Link to="/projects/NSN?tab=publications ">Publications</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/SAR">Search and Rescue (SAR)</Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/SAR?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/SAR?tab=technology">Technology</Link>
                      </li>
                      <li>
                        <Link to="/projects/SAR?tab=artemis">Artemis</Link>
                      </li>
                      <li>
                        <Link to="/projects/SAR?tab=commercial%20crew">Commercial Crew</Link>
                      </li>
                      <li>
                        <Link to="/projects/SAR?tab=safety%20studies">Safety Studies</Link>
                      </li>
                      <li>
                        <Link to="/projects/SAR?tab=publications">Publications</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/projects/TEMPO">Technology Enterprise and Mission Pathfinder Office</Link>
                    <ul className={classes.subsubUL}>
                      <li>
                        <Link to="/projects/TEMPO?tab=overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/projects/TEMPO?tab=incubated%20projects">Incubated Projects</Link>
                      </li>
                      <li>
                        <Link to="/projects/TEMPO?tab=pathfinder%20missions">Pathfinder Missions</Link>
                      </li>
                      <li>
                        <Link to="/projects/TEMPO?tab=technology%20development">Technology Development</Link>
                      </li>
                      <li>
                        <Link to="/projects/TEMPO?tab=lunanet">LunaNet</Link>
                      </li>
                      <li>
                        <Link to="/projects/TEMPO?tab=publications">Publications</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                {' '}
                <Link to="/partnerships">Partnerships</Link>
              </li>
              <ul className={classes.subUL}>
                <li>
                  <Link to="/partnerships/DC-QNet">DC-QNet</Link>
                  <ul className={classes.subsubUL}>
                    <li>
                      <Link to="/partnerships/DC-QNet?tab=overview">Overview</Link>
                    </li>
                    <li>
                      <Link to="/partnerships/DC-QNet?tab=members">Members</Link>
                    </li>
                    <li>
                      <Link to="/partnerships/DC-QNet?tab=research">Research</Link>
                    </li>
                    <li>
                      <Link to="/partnerships/DC-QNet?tab=publications ">Publications</Link>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className={classes.subUL}>
                <li>
                  <Link to="/partnerships/XGEO-METRO">XGEO METRO</Link>
                  <ul className={classes.subsubUL}>
                    <li>
                      <Link to="/partnerships/XGEO-METRO?tab=overview">Overview</Link>
                    </li>
                  </ul>
                </li>
              </ul>

              <li>
                <Link to="/partnerships/DC-QNet">Obtain Services</Link>
                <ul className={classes.subUL}>
                  <li>
                    <Link to="/networkIntServices">Communications & Navigation Network Services</Link>
                    <ul className={classes.subUL}>
                      <li>
                        <Link to="/networkIntServices?tab=about">About</Link>
                      </li>
                      <li>
                        <Link to="/networkIntServices?tab=service%20inquiry%20form">Service Inquiry Form</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/otherServices">Other Services</Link>
                    <ul className={classes.subUL}>
                      <li>
                        <Link to="/otherServices?tab=explore%20services">Explore Services</Link>
                      </li>
                      <li>
                        <Link to="/otherServices?tab=contact%20us">Contact Us</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                {' '}
                <Link to="/news-media">News &amp; Multimedia</Link>
                <ul className={classes.subUL}>
                  <li>
                    News/Blogs
                    <ul className={classes.subsubUL}>
                      {slugs.map((slug, ind) => (
                        <li key={ind}>
                          {' '}
                          <Link to={`../news/${slug.Slug}`}>{slug.Title}</Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <Link to="/video">Videos</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Galleries</Link>
                  </li>
                  <li>
                    <Link to="/publication">Publications</Link>
                  </li>
                </ul>
              </li>
              <li>
                {' '}
                <Link to="/participate">Participate</Link>
                <ul className={classes.subUL}>
                  <li>
                    <Link to="/participate?tab=interns">Interns</Link>
                  </li>
                  <li>
                    <Link to="/participate?tab=educators">Educators</Link>
                  </li>
                  <li>
                    <Link to="/participate?tab=visit%20with%20nasa">Visit With NASA</Link>
                  </li>
                  <li>
                    <Link to="/participate?tab=contact%20us">Contact Us</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SiteMap

export const SlugQuery = graphql`
  query SlugQuery {
    allStrapiNew(sort: { order: DESC, fields: Publish }, filter: { IsDataExample: { eq: false } }) {
      edges {
        node {
          Slug
          Title
        }
      }
    }
  }
`
