import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Link } from 'gatsby'
import ImageOverlay from './imageOverlay'
import sharedStyles from '../styles/global'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  galleryTextWrapper: {
    textAlign: 'center',
  },
  galleryText: {
    color: 'white',
    display: 'inline-table',
    verticalAlign: 'middle',
    lineHeight: 1.25,
  },
  colorBox: (project) => ({
    width: '10px',
    height: '10px',
    display: 'inline-block',
    backgroundColor: project.Color,
    left: '5px',
    top: '5px',
  }),
}))

export default function ProjectCard(props) {
  const { project } = props
  const theme = useTheme()
  const css = useStyles(project)
  const cssSettings = {
    crop: false,
    image: { height: '200px' },
    container: {
      height: '200px',
      maxHeight: '200px',
      borderRadius: '5px',
      '&:hover': {
        transform: 'scale(1.03)',
        '& > div': {
          background: 'rgba(29,55,135,0.6)',
        },
      },
    },
    // TODO: adjust padding based on break points to prevent word wrap
    text: {
      paddingRight: '10px',
      paddingLeft: '10px',
      transform: 'translateX(-50%)',
      top: '35%',
    },
  }
  const acronym = project.ShowAcronym ? `(${project.Acronym})` : ''

  return (
    <Link to={`../projects/${project.Acronym}`}>
      <ImageOverlay alt={project.Name} css={cssSettings} image={project.Image.localFile}>
        <div className={css.galleryTextWrapper}>
          <span className={[css.pt20, css.sansProSemiBold, css.uppercase, css.galleryText].join(' ')}>
            <div className={css.colorBox} />
            &nbsp;&nbsp;
            {project.Name}
            <br />
            {acronym}
          </span>
        </div>
      </ImageOverlay>
    </Link>
  )
}
